// Import JS Modules
import translate from './tools/translate';
// A collection of functions for utilities business logic

const genericHelpers = {
  promisifiedXHR(url, method, data, returnErrorBody) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          resolve(xhr.responseText && JSON.parse(xhr.responseText));
        } else if (returnErrorBody) {
          try {
            reject(JSON.parse(xhr.responseText));
          } catch (_e) {
            reject(xhr.responseText);
          }
        } else {
          reject(xhr.statusText);
        }
      };
      xhr.onerror = () => reject(xhr.statusText);

      const { payload, querystring } = (() => {
        switch (method) {
          case 'POST':
            const formData = new FormData();
            for (const key in data) {
              formData.append(key, data[key]);
            }
            return {
              payload: formData,
            };

          default:
            const params = [];
            for (const key in data) {
              params.push([key, data[key]].join('='));
            }

            return {
              querystring: params.join('&'),
            };
        }
      })();

      xhr.open(method, querystring ? `${url}?${querystring}` : url);
      xhr.send(payload);
    });
  },
  findLocations: (url, postalCode, serviceFilter, limit) => {
    if (!postalCode) {
      return Promise.reject();
    }

    const params = {
      postal_code: postalCode,
      service_filter: serviceFilter,
      limit,
    };

    return new Promise((resolve, reject) => {
      genericHelpers.promisifiedXHR(url, 'GET', params)
        .then((locations) => {
          if (locations.length > 0) {
            resolve(locations);
          } else {
            reject(`We found 0 collection options near your ${translate('zip code')}. Please try again with a different ${translate('zip code')}.`);
          }
        })
        .catch((error) => reject(error));
    });
  },
  debounce: (func, threshold, execAsap) => {
    let timeout;

    return function debounced() {
      const obj = this; const
        args = arguments;
      function delayed() {
        if (!execAsap) {
          func.apply(obj, args);
        }
        timeout = null;
      }

      if (timeout) {
        clearTimeout(timeout);
      } else if (execAsap) {
        func.apply(obj, args);
      }

      timeout = setTimeout(delayed, threshold || 100);
    };
  },
  isWasPriceValid: (product) => {
    if (product.was_price) {
      const today = genericHelpers.getDateWithoutTime(new Date());
      const { was_price } = product;
      const determiners = [was_price.value !== undefined];

      if (was_price.start_date) {
        determiners.push(today >= genericHelpers.getDateWithoutTime(new Date(was_price.start_date)));
      }
      if (was_price.end_date) {
        determiners.push(today <= genericHelpers.getDateWithoutTime(new Date(was_price.end_date)));
      }
      if (product.price) {
        determiners.push(was_price.value > product.price);
      }

      return determiners.every(Boolean);
    }
    return false;
  },
  imageMatchesSlotOrientation: (image, slot) => {
    const { width: imageWidth, height: imageHeight } = image;
    const { w, h, angle } = slot;

    const slotOrientation = genericHelpers.getOrientation(w, h)
    const imageOrientation = genericHelpers.getOrientation(imageWidth, imageHeight, angle);

    return imageOrientation === slotOrientation;
  },
  getOrientation: (width, height, angle = 0) => {
    if (angle === 90 || angle === 270) {
      return width > height ? 'portrait' : 'landscape';
    }

    if (width === height) {
      return 'square';
    } else {
      return width > height ? 'landscape' : 'portrait';
    }
  },
  getDateWithoutTime: (date) => date.setHours(0, 0, 0, 0),
  isMobileViewport: () => document.documentElement.clientWidth < 1024,
  // If the `stackable` attribute is true, it means stackable content is _disabled_.
  disallowStackable: () => window.wkData.stackable || genericHelpers.isMobileViewport(),
  isEmptyPrintedEnvelope: () => wkData.product_title.includes('Printed Envelope') && wkData.values.returnAddress === null,
  tidyTitleFilter: (title) => ({
    'num-photo': 'Number of Photos',
    'number of month': 'Number of Months',
  }[title] || title),
  calculateDeliveryDay: (hours) => {
    // (Time.now + hours.to_i.hours).strftime('%a, %b %e')
    const now = new Date();
    const ready = new Date(now);
    ready.setHours(now.getHours() + hours);

    const day_names = ['Sun', 'Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat'];
    const month_names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return `${day_names[ready.getDay()]}, ${month_names[ready.getMonth()]} ${ready.getDate()}`;
  },
  capitalizeString: (str = '') => str.charAt(0).toUpperCase() + str.slice(1),
  capitalizeWordsInString: (str = '') => str.replace('_', ' ').replace(/\w\S*/g, (wrd) => wrd.charAt(0).toUpperCase() + wrd.slice(1).toLowerCase()),
  shallowObjectEquals: (objectA = {}, objectB = {}) => {
    let key; let sizeA = 0; let
      sizeB = 0;

    for (key in objectA) {
      if (objectA.hasOwnProperty(key)) {
        sizeA++;
      }
    }

    for (key in objectB) {
      if (objectB.hasOwnProperty(key)) {
        sizeB++;
      }
    }

    if (sizeA !== sizeB) {
      return false;
    }

    for (key in objectA) {
      if (objectA[key] !== objectB[key]) {
        return false;
      }
    }
    return true;
  },
  deepObjectEquals: (a, b, enforce_properties_order, cyclic) => {
    return a === b // strick equality should be enough unless zero
      && a !== 0 // because 0 === -0, requires test by _equals()
      || _equals(a, b) // handles not strictly equal or zero values
    ;

    function _equals(a, b) {
      // a and b have already failed test for strict equality or are zero

      let s; let l; let p; let x; let y;
      const { toString } = Object.prototype;

      // They should have the same toString() signature
      if ((s = toString.call(a)) !== toString.call(b)) {
        return false;
      }

      switch (s) {
        default: // Boolean, Date, String
          return a.valueOf() === b.valueOf();

        case '[object Number]':
          // Converts Number instances into primitive values
          // This is required also for NaN test bellow
          a = +a;
          b = +b;

          return a // a is Non-zero and Non-NaN
            ? a === b
            : // a is 0, -0 or NaN
            a === a // a is 0 or -O
              ? 1 / a === 1 / b // 1/0 !== 1/-0 because Infinity !== -Infinity
              : b !== b // NaN, the only Number not equal to itself!
          ;
          // [object Number]

        case '[object RegExp]':
          return a.source == b.source
            && a.global == b.global
            && a.ignoreCase == b.ignoreCase
            && a.multiline == b.multiline
            && a.lastIndex == b.lastIndex
          ;
          // [object RegExp]

        case '[object Function]':
          return false; // functions should be strictly equal because of closure context
          // [object Function]

        case '[object Array]':
          if (cyclic && (x = reference_equals(a, b)) !== null) {
            return x;
          } // intentionally duplicated bellow for [object Object]

          if ((l = a.length) != b.length) {
            return false;
          }
          // Both have as many elements

          while (l--) {
            if ((x = a[l]) === (y = b[l]) && x !== 0 || _equals(x, y)) {
              continue;
            }

            return false;
          }

          return true;
          // [object Array]

        case '[object Object]':
          if (cyclic && (x = reference_equals(a, b)) !== null) {
            return x;
          } // intentionally duplicated from above for [object Array]

          l = 0; // counter of own properties

          if (enforce_properties_order) {
            const properties = [];

            for (p in a) {
              if (a.hasOwnProperty(p)) {
                properties.push(p);

                if ((x = a[p]) === (y = b[p]) && x !== 0 || _equals(x, y)) {
                  continue;
                }

                return false;
              }
            }

            // Check if 'b' has as the same properties as 'a' in the same order
            for (p in b) {
              if (b.hasOwnProperty(p) && properties[l++] != p) {
                return false;
              }
            }
          } else {
            for (p in a) {
              if (a.hasOwnProperty(p)) {
                ++l;

                if ((x = a[p]) === (y = b[p]) && x !== 0 || _equals(x, y)) {
                  continue;
                }

                return false;
              }
            }

            // Check if 'b' has as not more own properties than 'a'
            for (p in b) {
              if (b.hasOwnProperty(p) && --l < 0) {
                return false;
              }
            }
          }

          return true;
        // [object Object]
      } // switch toString.call( a )
    } // _equals()

    /* -----------------------------------------------------------------------------------------
       reference_equals( a, b )

       Helper function to compare object references on cyclic objects or arrays.

       Returns:
         - null if a or b is not part of a cycle, adding them to object_references array
         - true: same cycle found for a and b
         - false: different cycle found for a and b

       On the first call of a specific invocation of equal(), replaces self with inner function
       holding object_references array object in closure context.

       This allows to create a context only if and when an invocation of equal() compares
       objects or arrays.
    */
    function reference_equals(a, b) {
      const object_references = [];

      return (reference_equals = _reference_equals)(a, b);

      function _reference_equals(a, b) {
        let l = object_references.length;

        while (l--) {
          if (object_references[l--] === b) {
            return object_references[l] === a;
          }
        }

        object_references.push(a, b);

        return null;
      } // _reference_equals()
    } // reference_equals()
  },
  clamp: (num, min, max) => (num <= min ? min : num >= max ? max : num),
};

export default genericHelpers;
